<template>
  <div class="login">
    <div class="content content--no-tabs">
      <div class="container grid-md">
          <div class="columns">
            <div class="column col-11 col-sm-12">
              <h1>{{ $t('thanks.headline') }}</h1>
              <div v-html="$t('thanks.text')" />
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  components: {},
}
</script>

<style lang="scss" scoped>
  .intro {
    padding: 2rem 0 4rem 0;
  }

  .content {
    background: $color-primary-50;
  }

  .col-5.col-sm-12.col-md-6.col-lg-5 {
    max-width: 25.8rem;
  }

  .radio {
    input {
      display: none;
    }

    span {
      display: inline-block;
      color: #AAD9D5;
      border: 2px solid #AAD9D5;
      padding: 0.7rem 1.5rem;
      margin: 1.5rem 1.5rem 2rem 0;
      line-height: 1rem;
      font-size: 1rem;
      border-radius: 3rem;
      text-align: center;
      transition: all $transition-duration $transition-easing;
      cursor: pointer;
    }

    input:checked + span {
      color: $color-primary;
      border-color: $color-primary;
    }
  }

  .teaser {
  margin-top: 2rem;
  margin-bottom: 2rem;

    @media (min-width: $size-md + 1px) {
      margin-top: 4rem;

      &--push-down {
        margin-top: $header-wrapper;
      }
    }
  }

  .input {
    display: block;
    width: 100%;
    border: 0;
    padding: .4rem 1.5rem .6rem;
    margin: 0 0 1.5rem 0;
    line-height: 1.5rem;
    transition: $transition-duration/2 $transition-easing;
    outline: 2px solid rgba(#AAD9D5, 0);

    &:focus,
    &:active {
      outline: 2px solid rgba(#AAD9D5, 1);
    }
  }

  textarea {
    display: block;
    width: 100%;
    height: 12rem;
    border: 0;
    padding: .7rem 1.5rem .6rem;
    margin: 0 0 1.5rem 0;
    transition: $transition-duration/2 $transition-easing;
    outline: 2px solid rgba(#AAD9D5, 0);

    &:focus,
    &:active {
      outline: 2px solid rgba(#AAD9D5, 1);
    }
  }

  .form-actions {
    @media (min-width: $size-md) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .button {
        margin: 0;
      }
    }
  }
  .direct-contact {
    @media (min-width: $size-md) {
      margin-top: 0;
    }

    display: block;
    margin-top: 2rem;
    width: 100%;

    a {
      @media (min-width: $size-md) {
        float: right;
        margin-left: 1.5rem;
      }

      display: block;
    }
  }
</style>
